<template>
  <v-container class="page-container p-3">
    <h1>This is an about page</h1>
    <ExpenseDefaultPricingTable
      :list-data="listData"
      :isFirstLoading="isFirstLoading"
      :loadingTable="loadingSearch"
    />
  </v-container>
</template>
<script>
import api from "@/services"
import ExpenseDefaultPricingTable from "../components/Table/ExpenseDefaultPricingTable.vue"
export default {
  components: {
    ExpenseDefaultPricingTable,
  },
  data() {
    return {
      listData: [],
      loadingSearch: false,
      isFirstLoading: true,
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    async getListData() {
      this.loadingSearch = true
      const query = { ...this.$route.query }
      const payload = { ...query }
      const res = await api.revenueCategories.getListData(payload)
      this.isFirstLoading = false
      this.loadingSearch = false
      this.firstSync = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.getCompaniesFail,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        // const dataObj = res.data.data
        this.listData = [
          {
            id: 103,
            order_type_id: 8,
            expense_category_id: 55,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 55,
              name: "Ph\u00ed h\u1ea1 t\u1ea7ng",
            },
            vehicle_prices: [
              {
                id: 1939,
                vehicle_type_id: 10,
                price: 150000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 1940,
                vehicle_type_id: 20,
                price: 200000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 1941,
                vehicle_type_id: 30,
                price: 200000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 1942,
                vehicle_type_id: 40,
                price: 300000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 1943,
                vehicle_type_id: 50,
                price: 300000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 1944,
                vehicle_type_id: 60,
                price: 300000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 1945,
                vehicle_type_id: 70,
                price: 500000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 1946,
                vehicle_type_id: 80,
                price: 500000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 1947,
                vehicle_type_id: 90,
                price: 500000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 1948,
                vehicle_type_id: 100,
                price: 800000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 1949,
                vehicle_type_id: 110,
                price: 500000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 1950,
                vehicle_type_id: 120,
                price: 800000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 1951,
                vehicle_type_id: 130,
                price: 800000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 1952,
                vehicle_type_id: 140,
                price: 800000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 1953,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 1954,
                vehicle_type_id: 150,
                price: 800000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 1955,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 1956,
                vehicle_type_id: 160,
                price: 800000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 1957,
                vehicle_type_id: 170,
                price: 800000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 104,
            order_type_id: 8,
            expense_category_id: 2,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 2,
              name: "L\u1ec7 ph\u00ed c\u1eeda kh\u1ea9u xe Vi\u1ec7t Nam",
            },
            vehicle_prices: [
              {
                id: 1958,
                vehicle_type_id: 10,
                price: 10000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 1959,
                vehicle_type_id: 20,
                price: 10000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 1960,
                vehicle_type_id: 30,
                price: 10000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 1961,
                vehicle_type_id: 40,
                price: 10000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 1962,
                vehicle_type_id: 50,
                price: 10000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 1963,
                vehicle_type_id: 60,
                price: 10000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 1964,
                vehicle_type_id: 70,
                price: 10000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 1965,
                vehicle_type_id: 80,
                price: 10000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 1966,
                vehicle_type_id: 90,
                price: 10000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 1967,
                vehicle_type_id: 100,
                price: 10000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 1968,
                vehicle_type_id: 110,
                price: 10000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 1969,
                vehicle_type_id: 120,
                price: 10000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 1970,
                vehicle_type_id: 130,
                price: 10000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 1971,
                vehicle_type_id: 140,
                price: 10000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 1972,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 1973,
                vehicle_type_id: 150,
                price: 10000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 1974,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 1975,
                vehicle_type_id: 160,
                price: 10000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 1976,
                vehicle_type_id: 170,
                price: 10000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 105,
            order_type_id: 8,
            expense_category_id: 3,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 3,
              name: "Ph\u00ed t\u00e1ch tr\u1ea3 h\u1ed3 s\u01a1 h\u1ea3i quan",
            },
            vehicle_prices: [
              {
                id: 1977,
                vehicle_type_id: 10,
                price: 500000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 1978,
                vehicle_type_id: 20,
                price: 500000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 1979,
                vehicle_type_id: 30,
                price: 500000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 1980,
                vehicle_type_id: 40,
                price: 500000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 1981,
                vehicle_type_id: 50,
                price: 500000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 1982,
                vehicle_type_id: 60,
                price: 500000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 1983,
                vehicle_type_id: 70,
                price: 500000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 1984,
                vehicle_type_id: 80,
                price: 500000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 1985,
                vehicle_type_id: 90,
                price: 500000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 1986,
                vehicle_type_id: 100,
                price: 500000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 1987,
                vehicle_type_id: 110,
                price: 500000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 1988,
                vehicle_type_id: 120,
                price: 500000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 1989,
                vehicle_type_id: 130,
                price: 500000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 1990,
                vehicle_type_id: 140,
                price: 500000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 1991,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 1992,
                vehicle_type_id: 150,
                price: 500000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 1993,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 1994,
                vehicle_type_id: 160,
                price: 500000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 1995,
                vehicle_type_id: 170,
                price: 500000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 106,
            order_type_id: 8,
            expense_category_id: 7,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 7,
              name: "Ph\u00ed ki\u00ea\u0309m di\u0323ch y t\u00ea\u0301 xe Vi\u1ec7t Nam",
            },
            vehicle_prices: [
              {
                id: 1996,
                vehicle_type_id: 10,
                price: 200000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 1997,
                vehicle_type_id: 20,
                price: 200000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 1998,
                vehicle_type_id: 30,
                price: 200000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 1999,
                vehicle_type_id: 40,
                price: 200000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2000,
                vehicle_type_id: 50,
                price: 200000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2001,
                vehicle_type_id: 60,
                price: 200000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2002,
                vehicle_type_id: 70,
                price: 200000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2003,
                vehicle_type_id: 80,
                price: 200000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2004,
                vehicle_type_id: 90,
                price: 200000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2005,
                vehicle_type_id: 100,
                price: 200000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2006,
                vehicle_type_id: 110,
                price: 200000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2007,
                vehicle_type_id: 120,
                price: 200000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2008,
                vehicle_type_id: 130,
                price: 200000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2009,
                vehicle_type_id: 140,
                price: 200000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2010,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2011,
                vehicle_type_id: 150,
                price: 200000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2012,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2013,
                vehicle_type_id: 160,
                price: 200000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2014,
                vehicle_type_id: 170,
                price: 200000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 107,
            order_type_id: 8,
            expense_category_id: 41,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 41,
              name: "Ph\u00ed bi\u00ean ph\u00f2ng xe Vi\u1ec7t Nam",
            },
            vehicle_prices: [
              {
                id: 2015,
                vehicle_type_id: 10,
                price: 500000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2016,
                vehicle_type_id: 20,
                price: 500000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2017,
                vehicle_type_id: 30,
                price: 500000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2018,
                vehicle_type_id: 40,
                price: 500000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2019,
                vehicle_type_id: 50,
                price: 500000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2020,
                vehicle_type_id: 60,
                price: 500000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2021,
                vehicle_type_id: 70,
                price: 500000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2022,
                vehicle_type_id: 80,
                price: 500000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2023,
                vehicle_type_id: 90,
                price: 500000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2024,
                vehicle_type_id: 100,
                price: 500000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2025,
                vehicle_type_id: 110,
                price: 500000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2026,
                vehicle_type_id: 120,
                price: 500000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2027,
                vehicle_type_id: 130,
                price: 500000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2028,
                vehicle_type_id: 140,
                price: 500000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2029,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2030,
                vehicle_type_id: 150,
                price: 500000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2031,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2032,
                vehicle_type_id: 160,
                price: 500000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2033,
                vehicle_type_id: 170,
                price: 500000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 108,
            order_type_id: 8,
            expense_category_id: 17,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 17,
              name: "Ph\u00ed 01 c\u1ee7a xe Trung Qu\u1ed1c",
            },
            vehicle_prices: [
              {
                id: 2034,
                vehicle_type_id: 10,
                price: 45000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2035,
                vehicle_type_id: 20,
                price: 60000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2036,
                vehicle_type_id: 30,
                price: 60000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2037,
                vehicle_type_id: 40,
                price: 90000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2038,
                vehicle_type_id: 50,
                price: 90000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2039,
                vehicle_type_id: 60,
                price: 90000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2040,
                vehicle_type_id: 70,
                price: 150000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2041,
                vehicle_type_id: 80,
                price: 150000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2042,
                vehicle_type_id: 90,
                price: 150000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2043,
                vehicle_type_id: 100,
                price: 240000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2044,
                vehicle_type_id: 110,
                price: 150000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2045,
                vehicle_type_id: 120,
                price: 240000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2046,
                vehicle_type_id: 130,
                price: 240000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2047,
                vehicle_type_id: 140,
                price: 240000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2048,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2049,
                vehicle_type_id: 150,
                price: 240000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2050,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2051,
                vehicle_type_id: 160,
                price: 240000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2052,
                vehicle_type_id: 170,
                price: 240000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 109,
            order_type_id: 8,
            expense_category_id: 18,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 18,
              name: "L\u1ec7 ph\u00ed c\u1eeda kh\u1ea9u c\u1ee7a xe Trung Qu\u1ed1c",
            },
            vehicle_prices: [
              {
                id: 2053,
                vehicle_type_id: 10,
                price: 10000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2054,
                vehicle_type_id: 20,
                price: 10000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2055,
                vehicle_type_id: 30,
                price: 10000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2056,
                vehicle_type_id: 40,
                price: 10000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2057,
                vehicle_type_id: 50,
                price: 10000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2058,
                vehicle_type_id: 60,
                price: 10000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2059,
                vehicle_type_id: 70,
                price: 10000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2060,
                vehicle_type_id: 80,
                price: 10000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2061,
                vehicle_type_id: 90,
                price: 10000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2062,
                vehicle_type_id: 100,
                price: 10000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2063,
                vehicle_type_id: 110,
                price: 10000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2064,
                vehicle_type_id: 120,
                price: 10000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2065,
                vehicle_type_id: 130,
                price: 10000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2066,
                vehicle_type_id: 140,
                price: 10000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2067,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2068,
                vehicle_type_id: 150,
                price: 10000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2069,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2070,
                vehicle_type_id: 160,
                price: 10000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2071,
                vehicle_type_id: 170,
                price: 10000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 110,
            order_type_id: 8,
            expense_category_id: 42,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 42,
              name: "Bi\u00ean ph\u00f2ng xe Trung Qu\u1ed1c",
            },
            vehicle_prices: [
              {
                id: 2072,
                vehicle_type_id: 10,
                price: 220000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2073,
                vehicle_type_id: 20,
                price: 220000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2074,
                vehicle_type_id: 30,
                price: 220000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2075,
                vehicle_type_id: 40,
                price: 220000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2076,
                vehicle_type_id: 50,
                price: 220000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2077,
                vehicle_type_id: 60,
                price: 220000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2078,
                vehicle_type_id: 70,
                price: 220000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2079,
                vehicle_type_id: 80,
                price: 220000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2080,
                vehicle_type_id: 90,
                price: 220000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2081,
                vehicle_type_id: 100,
                price: 220000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2082,
                vehicle_type_id: 110,
                price: 220000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2083,
                vehicle_type_id: 120,
                price: 220000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2084,
                vehicle_type_id: 130,
                price: 220000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2085,
                vehicle_type_id: 140,
                price: 220000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2086,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2087,
                vehicle_type_id: 150,
                price: 220000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2088,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2089,
                vehicle_type_id: 160,
                price: 220000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2090,
                vehicle_type_id: 170,
                price: 220000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 111,
            order_type_id: 8,
            expense_category_id: 15,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 15,
              name: "Ki\u1ec3m d\u1ecbch y t\u1ebf xe Trung Qu\u1ed1c",
            },
            vehicle_prices: [
              {
                id: 2091,
                vehicle_type_id: 10,
                price: 100000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2092,
                vehicle_type_id: 20,
                price: 100000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2093,
                vehicle_type_id: 30,
                price: 100000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2094,
                vehicle_type_id: 40,
                price: 100000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2095,
                vehicle_type_id: 50,
                price: 100000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2096,
                vehicle_type_id: 60,
                price: 100000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2097,
                vehicle_type_id: 70,
                price: 100000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2098,
                vehicle_type_id: 80,
                price: 100000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2099,
                vehicle_type_id: 90,
                price: 100000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2100,
                vehicle_type_id: 100,
                price: 100000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2101,
                vehicle_type_id: 110,
                price: 100000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2102,
                vehicle_type_id: 120,
                price: 100000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2103,
                vehicle_type_id: 130,
                price: 100000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2104,
                vehicle_type_id: 140,
                price: 100000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2105,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2106,
                vehicle_type_id: 150,
                price: 100000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2107,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2108,
                vehicle_type_id: 160,
                price: 100000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2109,
                vehicle_type_id: 170,
                price: 100000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 112,
            order_type_id: 8,
            expense_category_id: 38,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 38,
              name: "D\u1ecbch v\u1ee5 th\u00f4ng quan",
            },
            vehicle_prices: [
              {
                id: 2110,
                vehicle_type_id: 10,
                price: 500000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2111,
                vehicle_type_id: 20,
                price: 500000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2112,
                vehicle_type_id: 30,
                price: 500000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2113,
                vehicle_type_id: 40,
                price: 500000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2114,
                vehicle_type_id: 50,
                price: 500000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2115,
                vehicle_type_id: 60,
                price: 500000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2116,
                vehicle_type_id: 70,
                price: 500000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2117,
                vehicle_type_id: 80,
                price: 500000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2118,
                vehicle_type_id: 90,
                price: 500000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2119,
                vehicle_type_id: 100,
                price: 500000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2120,
                vehicle_type_id: 110,
                price: 500000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2121,
                vehicle_type_id: 120,
                price: 500000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2122,
                vehicle_type_id: 130,
                price: 500000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2123,
                vehicle_type_id: 140,
                price: 500000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2124,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2125,
                vehicle_type_id: 150,
                price: 500000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2126,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2127,
                vehicle_type_id: 160,
                price: 500000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2128,
                vehicle_type_id: 170,
                price: 500000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 113,
            order_type_id: 8,
            expense_category_id: 43,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 43,
              name: "\u0110\u1ea7u k\u00e9o B\u1ea3o Nguy\u00ean (\u0111\u00e3 bao g\u1ed3m ph\u00ed b\u1ebfn b\u00e3i xe Trung Qu\u1ed1c)",
            },
            vehicle_prices: [
              {
                id: 2129,
                vehicle_type_id: 10,
                price: 2363636,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2130,
                vehicle_type_id: 20,
                price: 2363636,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2131,
                vehicle_type_id: 30,
                price: 2363636,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2132,
                vehicle_type_id: 40,
                price: 2363636,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2133,
                vehicle_type_id: 50,
                price: 2363636,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2134,
                vehicle_type_id: 60,
                price: 2363636,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2135,
                vehicle_type_id: 70,
                price: 2363636,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2136,
                vehicle_type_id: 80,
                price: 2363636,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2137,
                vehicle_type_id: 90,
                price: 2363636,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2138,
                vehicle_type_id: 100,
                price: 2363636,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2139,
                vehicle_type_id: 110,
                price: 2363636,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2140,
                vehicle_type_id: 120,
                price: 2363636,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2141,
                vehicle_type_id: 130,
                price: 2363636,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2142,
                vehicle_type_id: 140,
                price: 2363636,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2143,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2144,
                vehicle_type_id: 150,
                price: 2363636,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2145,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2146,
                vehicle_type_id: 160,
                price: 2363636,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2147,
                vehicle_type_id: 170,
                price: 2363636,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 114,
            order_type_id: 8,
            expense_category_id: 8,
            auto_check: true,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 8,
              name: "B\u1ebfn b\u00e3i xe Vi\u1ec7t Nam",
            },
            vehicle_prices: [
              {
                id: 2148,
                vehicle_type_id: 10,
                price: 100000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2149,
                vehicle_type_id: 20,
                price: 100000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2150,
                vehicle_type_id: 30,
                price: 100000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2151,
                vehicle_type_id: 40,
                price: 150000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2152,
                vehicle_type_id: 50,
                price: 150000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2153,
                vehicle_type_id: 60,
                price: 150000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2154,
                vehicle_type_id: 70,
                price: 250000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2155,
                vehicle_type_id: 80,
                price: 250000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2156,
                vehicle_type_id: 90,
                price: 250000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2157,
                vehicle_type_id: 100,
                price: 250000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2158,
                vehicle_type_id: 110,
                price: 250000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2159,
                vehicle_type_id: 120,
                price: 400000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2160,
                vehicle_type_id: 130,
                price: 400000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2161,
                vehicle_type_id: 140,
                price: 400000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2162,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2163,
                vehicle_type_id: 150,
                price: 400000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2164,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2165,
                vehicle_type_id: 160,
                price: 400000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2166,
                vehicle_type_id: 170,
                price: 600000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 115,
            order_type_id: 8,
            expense_category_id: 13,
            auto_check: false,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 13,
              name: "Ph\u00ed sang t\u1ea3i",
            },
            vehicle_prices: [
              {
                id: 2167,
                vehicle_type_id: 10,
                price: 0,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2168,
                vehicle_type_id: 20,
                price: 0,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2169,
                vehicle_type_id: 30,
                price: 0,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2170,
                vehicle_type_id: 40,
                price: 0,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2171,
                vehicle_type_id: 50,
                price: 0,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2172,
                vehicle_type_id: 60,
                price: 0,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2173,
                vehicle_type_id: 70,
                price: 0,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2174,
                vehicle_type_id: 80,
                price: 0,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2175,
                vehicle_type_id: 90,
                price: 0,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2176,
                vehicle_type_id: 100,
                price: 0,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2177,
                vehicle_type_id: 110,
                price: 0,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2178,
                vehicle_type_id: 120,
                price: 0,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2179,
                vehicle_type_id: 130,
                price: 0,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2180,
                vehicle_type_id: 140,
                price: 0,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2181,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2182,
                vehicle_type_id: 150,
                price: 0,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2183,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2184,
                vehicle_type_id: 160,
                price: 0,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2185,
                vehicle_type_id: 170,
                price: 0,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 116,
            order_type_id: 8,
            expense_category_id: 19,
            auto_check: false,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 19,
              name: "B\u1ea3o hi\u1ec3m xe Trung Qu\u1ed1c",
            },
            vehicle_prices: [
              {
                id: 2186,
                vehicle_type_id: 10,
                price: 78000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2187,
                vehicle_type_id: 20,
                price: 78000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2188,
                vehicle_type_id: 30,
                price: 152000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2189,
                vehicle_type_id: 40,
                price: 152000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2190,
                vehicle_type_id: 50,
                price: 152000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2191,
                vehicle_type_id: 60,
                price: 152000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2192,
                vehicle_type_id: 70,
                price: 252000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2193,
                vehicle_type_id: 80,
                price: 252000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2194,
                vehicle_type_id: 90,
                price: 252000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2195,
                vehicle_type_id: 100,
                price: 293000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2196,
                vehicle_type_id: 110,
                price: 293000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2197,
                vehicle_type_id: 120,
                price: 293000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2198,
                vehicle_type_id: 130,
                price: 293000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2199,
                vehicle_type_id: 140,
                price: 293000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2200,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2201,
                vehicle_type_id: 150,
                price: 293000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2202,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2203,
                vehicle_type_id: 160,
                price: 293000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2204,
                vehicle_type_id: 170,
                price: 293000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
          {
            id: 117,
            order_type_id: 8,
            expense_category_id: 24,
            auto_check: false,
            order_type: {
              id: 8,
              name: "Nh\u1eadp kh\u1ea9u (xe VN b\u1ed1c h\u00e0ng t\u1ea1i B\u1ea3o Nguy\u00ean)",
              gate: {
                id: 20,
                name: "T\u00e2n Thanh",
              },
            },
            expense_category: {
              id: 24,
              name: "Ph\u00ed soi chi\u1ebfu",
            },
            vehicle_prices: [
              {
                id: 2205,
                vehicle_type_id: 10,
                price: 200000,
                vehicle_type: {
                  id: 10,
                  name: "Xe 1,25 t\u1ea5n",
                },
              },
              {
                id: 2206,
                vehicle_type_id: 20,
                price: 200000,
                vehicle_type: {
                  id: 20,
                  name: "Xe 2,5 t\u1ea5n",
                },
              },
              {
                id: 2207,
                vehicle_type_id: 30,
                price: 200000,
                vehicle_type: {
                  id: 30,
                  name: "Xe 3,5 t\u1ea5n",
                },
              },
              {
                id: 2208,
                vehicle_type_id: 40,
                price: 200000,
                vehicle_type: {
                  id: 40,
                  name: "Xe 5 t\u1ea5n",
                },
              },
              {
                id: 2209,
                vehicle_type_id: 50,
                price: 200000,
                vehicle_type: {
                  id: 50,
                  name: "Xe 7 t\u1ea5n",
                },
              },
              {
                id: 2210,
                vehicle_type_id: 60,
                price: 200000,
                vehicle_type: {
                  id: 60,
                  name: "Xe 8 t\u1ea5n",
                },
              },
              {
                id: 2211,
                vehicle_type_id: 70,
                price: 200000,
                vehicle_type: {
                  id: 70,
                  name: "Xe t\u1ea3i b\u1ea1t 9.6m",
                },
              },
              {
                id: 2212,
                vehicle_type_id: 80,
                price: 200000,
                vehicle_type: {
                  id: 80,
                  name: "Xe 10 t\u1ea5n",
                },
              },
              {
                id: 2213,
                vehicle_type_id: 90,
                price: 200000,
                vehicle_type: {
                  id: 90,
                  name: "Xe 15 t\u1ea5n",
                },
              },
              {
                id: 2214,
                vehicle_type_id: 100,
                price: 200000,
                vehicle_type: {
                  id: 100,
                  name: "Xe 18 t\u1ea5n",
                },
              },
              {
                id: 2215,
                vehicle_type_id: 110,
                price: 200000,
                vehicle_type: {
                  id: 110,
                  name: "Container 20'",
                },
              },
              {
                id: 2216,
                vehicle_type_id: 120,
                price: 200000,
                vehicle_type: {
                  id: 120,
                  name: "Container 40'",
                },
              },
              {
                id: 2217,
                vehicle_type_id: 130,
                price: 200000,
                vehicle_type: {
                  id: 130,
                  name: "Container 45'",
                },
              },
              {
                id: 2218,
                vehicle_type_id: 140,
                price: 200000,
                vehicle_type: {
                  id: 140,
                  name: "Mooc s\u00e0n 14m",
                },
              },
              {
                id: 2219,
                vehicle_type_id: 145,
                price: 0,
                vehicle_type: {
                  id: 145,
                  name: "Mooc r\u00e0o 12.3m",
                },
              },
              {
                id: 2220,
                vehicle_type_id: 150,
                price: 200000,
                vehicle_type: {
                  id: 150,
                  name: "Mooc r\u00e0o 14.3m",
                },
              },
              {
                id: 2221,
                vehicle_type_id: 155,
                price: 0,
                vehicle_type: {
                  id: 155,
                  name: "Mooc r\u00e0o 15.3m",
                },
              },
              {
                id: 2222,
                vehicle_type_id: 160,
                price: 200000,
                vehicle_type: {
                  id: 160,
                  name: "Fooc 15m",
                },
              },
              {
                id: 2223,
                vehicle_type_id: 170,
                price: 200000,
                vehicle_type: {
                  id: 170,
                  name: "Fooc 18m",
                },
              },
            ],
          },
        ]
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
  },
}
</script>
